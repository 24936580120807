import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    fetch('https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/pages/19')
      .then(response => response.json())
      .then(data => {
        //console.log('Fetched ports:', data);
        setPage(data);
      })
      .catch(error => {
        //console.error('Error fetching ports:', error);
        setPage([]); // Optionally set to an empty array on error
      });
  }, []);
  return (
    <div>
        {/* The cursor elements */}
      <div className="cursor cursor--small"></div>
      <canvas className="cursor cursor--canvas"></canvas>
      <div className="nav-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Navbar*/}
              <nav className="navbar-expand-md navbar fixed-top" id="navbar">
                <a className="navbar-brand" data-scroll="" href="/">
                  {/* Navbar Logo*/}
                  <img className="assets/img-fluid" src="assets/img/spslogo.svg" alt="Logo" />
                </a>
                <span className="navbar-menu ml-auto" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                  role="button"><span className="btn-line"></span></span>
                <div className="collapse navbar-collapse order-1 order-lg-0" id="navbarSupportedContent">
                  {/* Navbar menu*/}
                  {/* <ul className="navbar-nav mobile-menu">
                    <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                    <li className="nav-item"><a className="nav-link" href="aboutme">About Me</a></li>
                    <li className="nav-item"><a className="nav-link" href="portfolio">Portfolio</a></li>
                    <li className="nav-item"><a className="nav-link" href="contact">Contact</a></li>
                  </ul> */}
                  <div className="navbar-nav mobile-menu">
                    <nav>
                      <Link className="nav-link" to="/">Home</Link>
                      <Link className="nav-link" to="/aboutme">About Me</Link>
                      <Link className="nav-link" to="/portfolio">Portfolio</Link>
                      <Link className="nav-link" to="/contact">Contact</Link>
                    </nav>
                  </div>
                </div>
                {/* easy contact area */}
                <div className="rts-easy-contact-area">
                  <div>
                        <div>
                        <a href="tel:+971522302475" className="number">+971 52 230 2475</a>
                        <a href="mailto:sanoobachu@gmail.com" className="mail">sanoobachu@gmail.com</a>
                        </div>
                  </div>
                {/* {page? (
                  <div>
                    {page?.acf ? (
                        <div>
                        <a href={`tel:${page.acf.my_phone}`} className="number">
                        {page.acf.my_phone}
                        </a>
                        <a href={`mailto:${page.acf.my_email}`} className="mail">
                        {page.acf.my_email}
                        </a>
                        </div>
                      ) : (
                          <p>No Data</p>
                      )}
                  </div>
                  ) : (
                    <div className="loadeve">Loading...</div>
                  )} */}
                </div>
                {/* easy contact area End */}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header