import React from 'react'
import { useState, useEffect } from 'react';

function Aboutme() {
  const [page, setPage] = useState(null);
  
  useEffect(() => {
    fetch('https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/pages/17')
      .then(response => response.json())
      .then(data => {
        //console.log('Fetched ports:', data);
        setPage(data);
      })
      .catch(error => {
        //console.error('Error fetching ports:', error);
        setPage([]); // Optionally set to an empty array on error
      });
  }, []);
  const [years, setYears] = useState(11); // Initial years of experience
  const startYear = 2013; // Set the base year for the calculation

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const newYears = currentYear - startYear;
    setYears(newYears);
  }, []);
  return (
<div>
      {/* About lightbox*/}
      <div className="section-wrapper" id="aboutme">
        <div className="container">
          <div className="popup-close">
            <div className="close-btn" data-modal-close=""><span className="btn-line"></span></div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="lightbox-content margin-controler mb-0">
                {/* Info section*/}
                <div className="info-section single-section">


                <div className="row align-items-center">
                    <div className="col-12 col-lg-7 order-xl-1 order-md-2 order-sm-2 order-2 info-content">
                        <div>
                      <div className="content-block mb-40">
                        <h2 className="content-subtitle quote">Who am i?</h2>
                        <h6 className="content-title quote">I'm Sanoob Saleem</h6>
                        <div className="content-description">
                          <p>Full Stack Developer with over {years} years of extensive experience in web application development, possesses a profound knowledge of a diverse range of technologies, including PHP, WordPress, React, Flutter, Magento, and jQuery. My expertise spans across both front-end and back-end development, allowing to deliver comprehensive and robust solutions. My proficiency in PHP and WordPress makes me adept at creating dynamic and scalable web applications, while skills in React and Flutter enable to build modern and responsive user interfaces. I am committed to leveraging extensive technical knowledge to drive innovation and deliver high-quality digital solutions.</p>
                        </div>
                        <address className="content-info">
                          <div className="row">
                            <div className="col-12 col-md-6 single-info"><span>First Name:</span>
                              <p>Sanoob</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Last Name:</span>
                              <p>Saleem</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Email:</span>
                              <p><a href="mailto:sanoobachu@gmail.com">sanoobachu@gmail.com</a></p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Phone:</span>
                              <p><a href="tel:+971522302475">+971 52 230 2475</a></p>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-12 col-md-6 single-info"><span>WhatsApp:</span>
                              <p><a href={`https://api.whatsapp.com/send/?phone=971522302475&text=&type=phone_number&app_absent=0`}>+971 52 230 2475</a></p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Nationality:</span>
                              <p>Indian</p>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-12 col-md-6 single-info"><span>Languages Known:</span>
                              <p>English, Malayalam, Tamil, Hindi, and Arabic</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Address:</span>
                              <p>Al Nahda, Sharjah, UAE</p>
                            </div>
                          </div>
                        </address>

                      </div>
                      <div className="download-btn">
                        <a href={page?.acf ? page.acf.my_resume : "#"} className="rts-btn btn-main" target="_blank">Download CV</a>
                      </div>
                      </div>
                    </div>
                      <div className="col-lg-5 order-xl-2 order-md-1 order-sm-1 order-1">
                       <div className="thumbnail-about">
                       <img src="assets/img/spsnow.webp" alt="Sanoob Photo" className="about-image-main" />
                       
                       </div>
                     </div>
                     </div>
                  
                    {/* Content part*/}
                    {/* {page? (
                    <div className="row align-items-center">
                    <div className="col-12 col-lg-7 order-xl-1 order-md-2 order-sm-2 order-2 info-content">
                      {page?.acf ? (
                        <div>
                      <div className="content-block mb-40">
                        <h2 className="content-subtitle quote">Who am i?</h2>
                        <h6 className="content-title quote">I'm {page.acf.first_name} {page.acf.last_name}</h6>
                        <div className="content-description">
                          <p>{page.acf.my_description}</p>
                        </div>
                        <address className="content-info">
                          <div className="row">
                            <div className="col-12 col-md-6 single-info"><span>First Name:</span>
                              <p>{page.acf.first_name}</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Last Name:</span>
                              <p>{page.acf.last_name}</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Email:</span>
                              <p><a href={`mailto:${page.acf.my_email}`}>{page.acf.my_email}</a></p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Phone:</span>
                              <p><a href={`tel:${page.acf.my_phone}`}>{page.acf.my_phone}</a></p>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-12 col-md-6 single-info"><span>WhatsApp:</span>
                              <p><a href={`https://api.whatsapp.com/send/?phone=${page.acf.my_phone}&text=&type=phone_number&app_absent=0`}>{page.acf.my_whatsapp}</a></p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Nationality:</span>
                              <p>{page.acf.natioanlity}</p>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-12 col-md-6 single-info"><span>Languages Known:</span>
                              <p>{page.acf.languages}</p>
                            </div>
                            <div className="col-12 col-md-6 single-info"><span>Address:</span>
                              <p>{page.acf.address}</p>
                            </div>
                          </div>
                        </address>

                      </div>
                      <div className="download-btn">
                        <a href={page.acf.my_resume} className="rts-btn btn-main" target="_blank">Download CV</a>
                      </div>
                      </div>
                      ) : (
                        <p>No Data</p>
                      )}
                    </div>
                      <div className="col-lg-5 order-xl-2 order-md-1 order-sm-1 order-1">
                       <div className="thumbnail-about">
                         <img src={page.acf.my_photo} alt="about" className="about-image-main" />
                       </div>
                     </div>
                     </div>
                    ) : (
                      <div className="loadeve">Please Wait...</div>
                    )} */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Resume lightbox*/}
        <div className="section-wrapper" id="resumes">
          <div className="container">
            <div className="popup-close">
              <div className="close-btn" data-modal-close=""><span className="btn-line"></span></div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="lightbox-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-heading page-heading">
                        <h2 className="section-title">My <span>Resume</span></h2>
                        <span className="section-title-bg">Resume</span>
                      </div>
                    </div>
                  </div>
                  {/* Resume section*/}
                  {page? (
                  <div className="resume-section single-section">
                    {page?.acf ? (
                    <div className="row">
                      {/* Education part*/}
                      <div className="col-12 col-xl-12 col-md-12 col-sm-12 col-12">
                        <div className="col-block education">
                          <h3 className="col-title">Education</h3>
                          <div className="professional-item"><span className="item-arrow"><i
                                className="fa fa-graduation-cap"></i></span>
                            <span className="item-details">{page.acf.school__university_and_year}</span>
                            <h5 className="item-title">{page.acf.certificate}</h5>

                            <p className="item-description">{page.acf.gpa} GPA</p>
                          </div>
                          {/* <div className="professional-item"><span className="item-arrow"><i
                                className="fa fa-graduation-cap"></i></span>
                            <span className="item-details">University of NewYork / 2010 - 2012</span>
                            <h5 className="item-title">Master Degree</h5>

                            <p className="item-description">Lorem ipsum dolor sit amet consectetur adipiscing elit placerat
                              justo luctus elementum at pulvinar, hac pretium ridiculus duis rutrum arcu</p>
                          </div> */}
                        </div>
                      </div>
                      {/* Experience part*/}
                      <div className="col-12 col-xl-12 col-md-12 col-sm-12 col-12">
                        <div className="col-block experience">
                          <h3 className="col-title">Experience</h3>
                          <div className="professional-item"><span className="item-arrow"><i className="fa fa-briefcase"></i></span>
                            <span className="item-details">{page.acf.company_name_and_year}</span>
                            <h5 className="item-title">{page.acf.position}</h5>

                            <div dangerouslySetInnerHTML={{ __html: page.acf.job_role }} />
                          </div>
                          <div className="professional-item"><span className="item-arrow"><i className="fa fa-briefcase"></i></span>
                            <span className="item-details">{page.acf.company_name_and_year_2}</span>
                            <h5 className="item-title">{page.acf.position_2}</h5>

                            <div dangerouslySetInnerHTML={{ __html: page.acf.job_role_2 }} />
                          </div>
                          <div className="professional-item">
                            <span className="item-arrow after-none">
                              <i className="fa fa-briefcase"></i>
                            </span>
                            <span className="item-details">{page.acf.company_name_and_year_3}</span>
                            <h5 className="item-title">{page.acf.position_3}</h5>

                            <div dangerouslySetInnerHTML={{ __html: page.acf.job_role_3 }} />
                          </div>
                          <div className="professional-item">
                            <span className="item-arrow after-none">
                              <i className="fa fa-briefcase"></i>
                            </span>
                            <span className="item-details">{page.acf.company_name_and_year_4}</span>
                            <h5 className="item-title">{page.acf.position_4}</h5>

                            <div dangerouslySetInnerHTML={{ __html: page.acf.job_role_4 }} />
                          </div>
                          <div className="professional-item">
                            <span className="item-arrow after-none">
                              <i className="fa fa-briefcase"></i>
                            </span>
                            <span className="item-details">{page.acf.company_name_and_year_5}</span>
                            <h5 className="item-title">{page.acf.position_5}</h5>

                            <div dangerouslySetInnerHTML={{ __html: page.acf.job_role_5 }} />
                          </div>
                        </div>
                      </div>
                    </div>
                     ) : (
                      <p>No Data</p>
                    )}
                  </div>
                  ) : (
                    <div className="loadeve"></div>
                  )}
                  {/* Skills section*/}
                  {page? (
                  <div className="skills-section single-section">
                    <div className="row mb-50">
                      <div className="col-12">
                        <div className="section-heading page-heading">
                          <h2 className="section-title">My <span>Skills</span></h2>
                          <span className="section-title-bg">Quality</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row g-5 skills-wrapper-progres">
  {page?.acf? (
    Object.entries(page.acf).map(([key, value]) => {
      if(key === "wordpress" || key === "php" || key === "mysql" || key === "jquery" || key === "javascript" 
      || key === "react" || key === "next_js" || key === "flutter" || key === "html" || key === "css" || key === "bootstrap" 
      || key === "seo" || key === "magento" || key === "digital_marketing" || key === "photoshop" || key === "illustrator") {
      return (
        <div className="col-12 col-lg-6 mt-0 single-skill" data-percentage={value} key={key}>
         
            <div className="single-skills">
              <div className="skill-info">
                <span className="skill-name">{key.replace('_', ' ').toUpperCase()}</span>
                <span className="skill-percentage">{value}%</span>
              </div>
              <div className="progress skill-progress">
                <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{width: `${value}%`}}></div>
              </div>
            </div>
        </div>
      );
      }
    })
  ) : (
    <p>No Data</p>
  )}                    
                    </div>
            
                  </div>
                ) : (
                  <div className="loadeve">Please Wait...</div>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
  )
}

export default Aboutme