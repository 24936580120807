import React, { useState, useEffect } from 'react';

function Portfolio() {
  const [ports, setPorts] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // Fetch portfolio categories
  useEffect(() => {
    fetch('https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/portfolio-category')
      .then(response => response.json())
      .then(data => {
        setPortfolioData(data);
        setCategoryId(null); // Set to null to show all items by default
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  // Fetch portfolio items based on the selected category
  useEffect(() => {
    setIsLoading(true); // Start loading
    const url = categoryId
      ? `https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/portfolio?portfolio-category=${categoryId}&per_page=100&_embed`
      : `https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/portfolio?per_page=100&_embed`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setPorts(data);
        setIsLoading(false); // Stop loading
      })
      .catch(error => {
        console.error('Error fetching portfolios:', error);
        setPorts([]); // Optionally set to an empty array on error
        setIsLoading(false); // Stop loading even if there's an error
      });
  }, [categoryId]); // Re-fetch when categoryId changes

  // Handler for changing the selected category
  const handleCategoryChange = (id) => {
    setCategoryId(id);
  };

  return (
    <div>
      {/* Portfolio lightbox */}
      <div className="section-wrapper" id="portfolios">
        <div className="container">
          <div className="popup-close">
            <div className="close-btn" data-modal-close=""><span className="btn-line"></span></div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="lightbox-content">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading page-heading">
                      <h2 className="section-title">My <span>Portfolio</span></h2>
                      <span className="section-title-bg">Portfolio</span>
                    </div>
                  </div>
                </div>
                {/* Portfolio section */}
                <div className="portfolio-section single-section">
                  <div className="row">
                    {/* Filter nav */}
                    <div className="col-12">
                      <ul className="list-inline filter-control" role="group" aria-label="Filter Control">
                        <li
                          className={`list-inline-item ${categoryId === null ? 'tab-active' : ''}`}
                          onClick={() => handleCategoryChange(null)}
                        >
                          All
                        </li>
                        {portfolioData.map(category => (
                          <li
                            key={category.id}
                            className={`list-inline-item ${categoryId === category.id ? 'tab-active' : ''}`}
                            onClick={() => handleCategoryChange(category.id)}
                          >
                            {category.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* Check if loading */}
                  {isLoading ? (
                    <div className="loadeve">Please Wait...</div> // Display loading message
                  ) : (
                    <div className="portfolio-grid row row-40">
                      {/* Display filtered portfolio items */}
                      {ports.map(post => {
                        // Get the ACF URL, if available
                        const portfolioUrl = post.acf?.portfolio_url || '#';

                        return (
                          <div key={post.id} className="single-item col-6 col-lg-4 design">
                            <a className="portfolio-item" href={portfolioUrl} target="_blank" alt={post.title.rendered}>
                              <div className="portfolio-wrapper">
                                {post._embedded && post._embedded['wp:featuredmedia'][0] && (
                                  <img className="assets/img-fluid" src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
                                )}
                                <div className="eye-icon">
                                  <img src="assets/img/portfolio/01.png" alt="portfolio_area" />
                                </div>
                                <div className="item-content">
                                  {/* Link the title to the portfolioUrl */}
                                  <h6 className="content-title">
                                    <a 
                                      href={portfolioUrl} 
                                      target="_blank" 
                                      rel="noopener noreferrer" 
                                      dangerouslySetInnerHTML={{ __html: post.title.rendered }}>
                                    </a>
                                  </h6>
                                  {post._embedded && post._embedded['wp:term'][0] && (
                                    <div>
                                      {post._embedded['wp:term'][0].map(category => (
                                        <span key={category.id} className="content-more">{category.name}</span>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;