import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

function Contact() {

  const [page, setPage] = useState(null);

  useEffect(() => {
    fetch('https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/pages/19')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched ports:', data);
        setPage(data);
      })
      .catch(error => {
        console.error('Error fetching ports:', error);
        setPage([]); // Optionally set to an empty array on error
      });
  }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const apiUrl = 'http://localhost:8888/sanoobonline/backend/wp-json/contact-form-7/submit';
  //   const response = await fetch(apiUrl, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(formData),
  //   });
  //   const result = await response.json();
  //   if (result.success) {
  //     console.log('Form submitted successfully!');
  //   } else {
  //     console.error('Error submitting form:', result.error);
  //   }
  // };


  // useEffect(() => {
  //   fetch('http://localhost:8888/sanoobonline/backend/wp-json/customform/v1/shortcode')
  //     .then(response => response.json())
  //     .then(data => {
  //       //console.log('Fetched ports:', data);
  //       setFormHtml(data);
  //     })
  //     .catch(error => {
  //       //console.error('Error fetching ports:', error);
  //       setFormHtml([]); // Optionally set to an empty array on error
  //     });
  // }, []);


  return (
    <div>
        {/* Contact lightbox*/}
        <div className="section-wrapper" id="contacts">
          <div className="container">
            <div className="popup-close">
              <div className="close-btn" data-modal-close=""><span className="btn-line"></span></div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="lightbox-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="section-heading page-heading">
                        <h2 className="section-title">Get in <span>Touch</span></h2>
                        <span className="section-title-bg">Contact</span>
                        {/* {page? (
                        <span className="section-title-bg">{page.title.rendered}</span>
                        ) : (
                          <div className="loadeve">Please Wait...</div>
                        )} */}
                        {/* <span className="section-title-bg">Contact Us</span> */}
                      </div>
                    </div>
                  </div>
                  {/* Contact section*/}
                  <div className="contact-section single-section">
                    <div className="row">
                      {/* Contact info*/}
                      <div className="col-12 col-lg-12">
                        <div className="contact-info">
                          <ul className="list-unstyled list-info">
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                      <div>
                                        <h6 className="info-type">Name</h6><span className="info-value">Sanoob Saleem</span>
                                      </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                      <div>
                                        <h6 className="info-type">Phone</h6><span className="info-value"><a href="tel:+971522302475">+971 52 230 2475</a></span>
                                      </div>                                 
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                      <div>
                                        <h6 className="info-type">WhatsApp</h6><span className="info-value"><a href={`https://api.whatsapp.com/send/?phone=971522302475&text=&type=phone_number&app_absent=0`}>+971 52 230 2475</a></span>
                                      </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                      <div>
                                        <h6 className="info-type">Email Me</h6>
                                        <span className="info-value">
                                          <a href="mailto:sanoobachu@gmail.com">sanoobachu@gmail.com</a>
                                        </span>
                                      </div>
                                </div>
                              </div>
                            </li>
                          </ul>


                        {/* {page? (
                          <ul className="list-unstyled list-info">
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                {page?.acf ? (
                                      <div>
                                        <h6 className="info-type">Name</h6><span className="info-value">{page.acf.my_name}</span>
                                      </div>
                                    ) : (
                                      <p>No Data</p>
                                    )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                {page?.acf ? (
                                      <div>
                                        <h6 className="info-type">Phone</h6><span className="info-value"><a href={`tel:${page.acf.my_phone}`}>{page.acf.my_phone}</a></span>
                                      </div>
                                    ) : (
                                      <p>No Data</p>
                                    )}
                                  
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                {page?.acf ? (
                                      <div>
                                        <h6 className="info-type">WhatsApp</h6><span className="info-value"><a href={`https://api.whatsapp.com/send/?phone=${page.acf.my_phone}&text=&type=phone_number&app_absent=0`}>{page.acf.my_whatsapp}</a></span>
                                      </div>
                                    ) : (
                                      <p>No Data</p>
                                    )}
                                  
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="media align-items-center">
                                <div className="media-body info-details">
                                {page?.acf ? (
                                      <div>
                                        <h6 className="info-type">Email Me</h6>
                                        <span className="info-value">
                                          <a href={`mailto:${page.acf.my_email}`}>{page.acf.my_email}</a>
                                        </span>
                                      </div>
                                    ) : (
                                      <p>No Data</p>
                                    )}
                                  
                                </div>
                              </div>
                            </li>
                          </ul>
                          ) : (
                            <div className="loadeve"></div>
                          )} */}
                        </div>
                      </div>
                      {/* Contact form*/}
                      <div className="col-12 col-lg-12">
                      
                            <div className="contforms">
                              <h4 className="content-title">Send me a message</h4>
                              <iframe
        src="https://www.sanoobsaleem.com/sanoobonline/contact-us"  // Replace with your actual page URL
        width="100%"
        height="450px"
        frameBorder="0"
        style={{ border: 'none' }}
        title="Contact Form"
      ></iframe>
                              {/* <div dangerouslySetInnerHTML={{ __html: formHtml }} /> */}
                              {/* <button onClick={handleSubmit}>Submit</button> */}
                              {/* {responseMessage && <p>{responseMessage}</p>} */}
                            </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Contact