import React from 'react'
import { useState, useEffect } from 'react';

function Home() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    fetch('https://www.sanoobsaleem.com/sanoobonline/wp-json/wp/v2/pages/21')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched ports:', data);
        setPage(data);
      })
      .catch(error => {
        console.error('Error fetching ports:', error);
        setPage([]); // Optionally set to an empty array on error
      });
  }, []);

  const [years, setYears] = useState(11); // Initial years of experience
  const startYear = 2013; // Set the base year for the calculation

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const newYears = currentYear - startYear;
    setYears(newYears);
  }, []);
  return (
    <div>
      {/* Home*/}
      <section className="home-area element-cover-bg" id="home">

      <div className="image-content">
          <div className="round-shape-wrapper">
            <img src="assets/img/banner/banner-01-white.png" alt="banenr-bg" className="shape bg-1" />
            <img src="assets/img/banner/banner-02-white.png" alt="banenr-bg" className="shape bg-2" />
            <img src="assets/img/mypic.webp" alt="Sanoob Photo" className="shape bg-3" />
          </div>
        </div>
      {/* {page? (
        <div className="image-content">
          {page?.acf ? (
          <div className="round-shape-wrapper">
            <img src="assets/img/banner/banner-01-white.png" alt="banenr-bg" className="shape bg-1" />
            <img src="assets/img/banner/banner-02-white.png" alt="banenr-bg" className="shape bg-2" />
            <img src={page.acf.my_photo} alt="banenr-bg" className="shape bg-3" />
          </div>
          ) : (
            <p>No Data</p>
          )}
        </div>
          ) : (
            <div className="loadeve">Loading...</div>
          )} */}
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-xl-6 col-lg-12 offset-lg-6 home-content-right banner-one">
              <h1 className="home-name">
                <span className="color-name quote">Hello I'm</span>
                <div>
                          <span className="upper-case banner quote">Sanoob Saleem</span>
                          <p>Full Stack Developer with over {years} years of extensive experience in web application development, possesses a profound knowledge of a diverse range of technologies, including PHP, WordPress, React, Flutter, Magento, jQuery, HTML, and CSS.</p>
                </div>
                {/* {page? (
                  <div>
                    {page?.acf ? (
                        <div>
                          <span className="upper-case banner quote">{page.acf.my_name}</span>
                          <p>{page.acf.my_description}</p>

                          </div>
                      ) : (
                          <p>No Data</p>
                      )}
                  </div>
                  ) : (
                    <div className="loadeve"></div>
                  )} */}
              </h1>
              <div className="social-banner-wrapper">
                <div className="text-area">
                  <p className="dest">Follow Me on:</p>
                </div>
                <div className="social">
                  <div>
                        <div>
                          <a href={page?.acf ? page.acf.my_facebook : "#"} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href={page?.acf ? page.acf.my_linkedin : "#"} target="_blank">
                            <i className="fab fa-linkedin"></i>
                          </a>
                          <a href={page?.acf ? page.acf.my_instagram : "#"} target="_blank">
                            <i className="fab fa-instagram"></i>
                          </a>
                          <a href={page?.acf ? page.acf.my_behance : "#"} target="_blank">
                            <i className="fab fa-behance"></i>
                          </a>
                          <a href={page?.acf ? page.acf.my_whatsapp : "#"} target="_blank">
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </div>
                  </div>
                </div>
              </div>
              <div className="button-group margin-top">
                <a href="/contact" className="rts-btn btn-main">
                  Send Message
                </a>
                <a href="https://api.whatsapp.com/send/?phone=971522302475&text=&type=phone_number&app_absent=0" className="rts-btn btn-main-alta">
                  Hire Me Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
  )
}

export default Home